@mixin font-bold {
  font-family: futura-pt, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin font-default {
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  font-style: normal;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  @include font-default();
}

.c-cover {
  background-image: url("./gfx/cover.jpg");
  background-size: cover;
  background-position: center center;
  display: flex;
  height: calc(100vh - 300px);
  position: relative;

  min-height: 500px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.7);
    z-index: 1;
  }
}

.c-footer {
  display: flex;
  min-height: 300px;

  @media (max-width: 680px) {
    display: block;
  }
}

.c-copy {
  margin: auto;
  align-self: center;
  padding: 50px;

  line-height: 1.3;

  h4 {
    font-size: 20px;
    margin-bottom: 1em;
  }

  font-size: 18px;

  li {
    list-style: none;
    &::before {
      content: "-";
    }
  }
}

.c-contact {
  background: #d65f00;
  color: #fff;
  position: relative;
  z-index: 20;

  font-size: 20px;
  line-height: 1.5;

  width: 100%;
  padding: 50px;

  @media (min-width: 681px) {
    width: 500px;
    padding: 130px 70px 80px;
    margin-top: -100px;
  }

  a {
    color: inherit;
  }
}

.c-contact__title {
  line-height: 1.2;
  margin-bottom: 1em;
}

.c-logo {
  width: 495px;
  display: block;
  margin: auto;
  position: relative;
  z-index: 5;
  max-width: 70vw;
}

.c-contact__actions {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-50%);
}

.c-contact__actions .c-gallery-button {
  appearance: none;
  border: 0;
  font: inherit;
  background: #fff;
  color: #000;
  text-transform: uppercase;

  font-size: 14px;
  letter-spacing: 1.5px;
  text-align: center;

  padding: 1em 1.5em;

  cursor: pointer;
  transition: 0.2s;
  text-decoration: none;

  &:hover {
    background: #000;
    color: #fff;
  }
}
